import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import "./axios/axios"

Vue.config.productionTip = false

//判断版本号
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("Version");
if(VUE_APP_VERSION != vers){
  localStorage.clear()
  window.localStorage.setItem("Version", VUE_APP_VERSION);
  location.reload()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/*
一、登录问题 √
1、后端写一个根据token查询用户的名字、用户身份，前端根据返回来的信息更新缓存里的信息
2、前端获取到缓存中的token，默认此token有效，直接进入用户信息页，但是在下一次发起请求时发现token失效返回到登录页让用户重新登录

二、添加影厅，修改影厅  √
1、弹出框点击遮罩层无法关闭
  解决办法：是否添加一个取消按钮
2、新增一条逻辑  添加时判断是否已经存在同名影厅

三、检测数据 √
正常选项为空？？
可以不为空

四、填写联系人姓名，联系人电话增加验证
联系人 姓名5个字 排除英文吗？

五、报告下载页面的搜索是干嘛用的？ √
搜索年份

六、axios拦截判断用户登录失效直接返回到登录页 √
拦截一切404状态 让用户重新登录 删除缓存中一切用户信息
登录成功后设置用户名字 token 用户身份 跳转至信息页
首先判断用户的身份是否进入对的页面,如果错误删除缓存,返回登录页
  判断是否存在用户信息
检测员会在获得工作安排时验证token created()
影院在添加影院时首先判断是否存在token 获得影厅列表时验证token created()
  在添加影院信息时首先判断是否存在token 获得影院信息时验证token created()
  报告下载时首先判断是否存在token 获得报告列表时验证token created()
  所以第一个问题可以解决,默认token有效

七、路由params传值，用name跳转，不传如何值，网站路径消失，但是页面改变，如果back返回直接回退2层页面 √
/testReport/:mid 与 /testReport 是不一样的

八、检测历史 √
最新检测的排最上面

九、弹窗提示 √
1、填写检测数据为空

十、无法识别行内样式
将全部行内样式转为类

十一、搜索影院的交互 √

十二、下载列表空状态 √

十三、填写检测数据 如果检测的大厅为空能提交吗
是直接不给提交，还是二次确认

十四、selectionHall页面问题
填完影厅后返回影厅列表时需要反复发送网络请求

十五、 √
next(vm=>{
  vm.$router.push("/testingLogin")
});
转为
next("/testingLogin")

十六、检测历史 修改检测  回退问题 √
选择大厅回退 退回到上一层修改数据页
解决：使用replace不记录路由的方法跳转

十七、修改检测数据 离开时询问用户是否保存

添加影厅弹框按钮 删除取消按钮 √ 点击的效果是否去掉？？√ 是否全部填充？？ √

报告 头部 影厅报告 √

报告下载 √

修改影厅 头部标题 √ 标题的提示语？？√

双标题 √

空状态图片不可选 √

登录页面增加提示显示身份 √ 提示语？？ √

检测历史为空时的状态 提示语？？ √

检测员 检测历史空状态出现空隙 √

影院下载电影报告 安卓能下载，苹果直接打开 √

影厅查看报告后返回出现闪烁，添加影厅页面的展示逻辑√

添加影厅删除按钮边框√

检测选择大厅为空时的状态√

检测填写报告 2D 3D输入框对齐问题√

用户投诉页面填写内容的逻辑√


建议：检测填写报告，未提交时返回，给个弹窗提示
*/