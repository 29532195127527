import axios from 'axios'
import qs from 'qs'
import { Toast } from 'vant';
import router from "../router";
//1. 创建新的axios实例，
const _axios = axios.create({
        baseURL: 'https://www.zjzlys.cn',
})

// 2.请求拦截器
_axios.interceptors.request.use(require=>{
    //发出请求拦截
    require.data.timestamp=Math.round(new Date().getTime() / 1000);
    require.data = qs.stringify(require.data);
    return require
},error=>{
    return Promise.reject(error);
})


// 3.响应拦截器
_axios.interceptors.response.use(response=>{
    //返回结果拦截
    if(response.data.state==="404"){
        Toast({message:"登录失效",icon:"cross"})
        localStorage.removeItem("uid");
        localStorage.removeItem("userName");
        localStorage.removeItem("token");
        if(localStorage.getItem("identity")==="3"){
            router.push("/testingLogin")
        }else if(localStorage.getItem("identity")==="0" || localStorage.getItem("identity")==="1"){
            router.push("/cinemaLogin")
        }else{
            //如果用户缓存里没有权限值呢???
            router.push("/cinemaLogin")
        }
        localStorage.removeItem("identity");
    }
    return response.data
},error=>{
    return Promise.reject(error)
})


export default _axios
