import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/CinemaLogin',
  },
  /* 影院 登录页面 */
  {path:"/cinemaLogin",name:"CinemaLogin",component: () => import('../views/CinemaLogin'),meta: {title:"浙江省电影放映协会"}},
  // 影院登录成功 影院信息页
  {path:"/memberUser",name:"MemberUser",component: () => import('../views/MemberUser'),meta: {title:"浙江省电影放映协会"}},
  {path:"/unMemberUser",name:"UnMemberUser",component: () => import('../views/UnMemberUser'),meta: {title:"浙江省电影放映协会"}},
  // 影院添加个人信息
  {path:"/movieInfoAdd",name:"MovieInfoAdd",component: () => import('../views/MovieInfoAdd'),meta: {title:"信息填写"}},
  // 影院添加影厅
  {path:"/cinemaAdd",name:"CinemaAdd",component: () => import('../views/CinemaAdd'),meta: {title:"添加影厅"}},
  // 影厅查看检测结果
  {path:"/cinemaTestingResult/:id",name:"CinemaTestingResult",component: () => import('../views/CinemaTestingResult'),meta: {title:"影厅报告"}},
  // 影院下载报告
  {path:"/fileDownload",name:"FileDownload",component: () => import('../views/FileDownload'),meta: {title:"报告下载"}},

  /* 检测人员 登陆界面 */
  {path:"/testingLogin",name:"TestingLogin",component: () => import('../views/TestingLogin'),meta: {title:"浙江省电影放映协会"}},
  // 检测员信息页
  {path:"/testingUser",name:"TestingUser",component: () => import('../views/TestingUser'),meta: {title:"浙江省电影放映协会"}},
  // 检测员检测报告填写-1 搜索影厅 选择影厅 显示影厅信息 填写检测小组信息
    //工作安排
  {path:"/testReport/:mid",name:"TestReport",component: () => import('../views/TestReport'),meta: {title:"检测报告"}},
    //导航过来的
  {path:"/testReport",name:"TestReport2",component: () => import('../views/TestReport'),meta: {title:"检测报告"}},
  // 检测员选择影厅填写-2
  {path:"/selectionHall/:id",name:"SelectionHall",component: () => import('../views/SelectionHall'),meta: {title:"选择大厅"}},
  //  只允许浏览
  {path:"/selectionHall/:id/:see",name:"SelectionHallsee",component: () => import('../views/SelectionHall'),meta: {title:"选择大厅"}},
  // 检测员 填写影厅检查数据-3
  {path:"/cinemaTesting/:name/:hid/:mid",name:"CinemaTesting",component: () => import('../views/CinemaTesting')},
  {path:"/cinemaTesting/:name/:hid/:mid/:hallid/:flag",name:"CinemaTesting2",component: () => import('../views/CinemaTesting')},
  // 检测员 检测历史
  {path:"/detectionHistory",name:"DetectionHistory",component: () => import('../views/DetectionHistory'),meta: {title:"检测历史"}},

  // 游客投诉
  {path:"/touristComplaint",name:"TouristComplaint",component: () => import('../views/TouristComplaint'),meta: {title:"投诉通道"}},

]


const router = new VueRouter({
  mode: 'history',
  base: '',
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
